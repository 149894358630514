.publication-search {

    &__container {
        text-align: left;

        @include for-tablet-landscape-up {
            text-align: center;            
        }

        .search-bar {
            width: 70%;
            height: 5rem;
            padding-left: 1rem;

            @include for-desktop-up {
                width: 65%;
            }

            @include for-tablet-landscape-up {
                width: 95%;                
            }
            
            @include for-tablet-portrait-up  {
                width: 70%;
            }
        }

        button {
            height: 5rem;
            width: 25%;

            @include for-tablet-landscape-up {
                width: 50%;                
            }

            @include for-tablet-portrait-up  {
                width: 25%;                
            }
        }
    }
}