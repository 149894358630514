.publication-single {

    .heading-secondary::first-letter {
        text-transform: capitalize;
    }
        
    &__cover {
        display: inline-block;
        position: relative;

        img {
            @include publication_animation;   
            width: 100%;
            height: auto;
            margin: 1rem;
            box-shadow: 0 0 .3rem rgba(0,0,0,.4);

            @include for-desktop-up  {
                width: 100%;
                height: auto;
            }

            @include for-phone-only  {
                width: 90%;
                height: auto;
            }
        }

        &__no-access-title {
            position: absolute;
            color: darkred;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 5;
            font-size: 2.4rem;
            font-weight: 600;
        }

        &__no-access-pub {
            z-index: 10;
            opacity: 0.3;
        }
    }
}

