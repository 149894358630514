.publication-detail-section {

  .header-title {
    position: absolute;
    left: 30%;
    top: -2.5rem;
    
  }

  &__header-wrapper {
    position: absolute;
    left: 50%;
    top: -2.5rem;

    &__container{
      position: relative;
      left: -50%;
    }
  }

  .newsletter-wrapper {
    display: flex;
    float: right;
    height: 0rem;

    &__container{
      position: relative;
      top: -3.5rem;
      left: -15%;
      text-align: right;
    }
  }

  .newsletter-wrapper-bottom {
    position: absolute;
    left: 50%;

    &__container{
      position: relative;
      left: -50%;
      text-align: right;
    }
  }

  .container {
      display: flex;
      justify-content: start;
      align-items: normal;

      &:hover {
        background-color: #f7fbfc;
        transform: translateY(-.1rem);
        box-shadow: 0 .2rem 1rem rgba(0,0,0,.3);
      }
  }

  .container img, .container p {
      flex-grow: 0;
  }

  .container img {
      max-width: 100px;
      max-height:60px;
  }

  .container p {
      margin-left: 4%;
      margin-top: 0%;
      text-align: left;
      font-family: "Reckless", sans-serif;
      font-weight: 400;
      font-size: 1.3rem;
  }
  
  .newsletter-link {
    color: #fcf9f9;
    text-decoration: none;
    font-family: "Reckless", sans-serif;
    font-size: 1.5rem;
  }

  .newsletter-link-bottom {
    color: black;
    text-decoration: none;
    font-family: "Reckless", sans-serif;
    font-size: 1.5rem;
  }

  .article-link {
    color: #080808;
    text-decoration: none;
    font-family: "Reckless", sans-serif;
    font-size: 1.5rem;
  }

  .regionCaption-link {
    color: #080808;
    text-decoration: none;
    font-family: "Reckless", sans-serif;
  }

  .heading-primary {
    text-align: left;
    font-weight: 400;
    color: white;
    width: 200%;
  }
    
    .heading-secondary {
      text-align: left;
      padding-top: 1.5rem;
    }
    
    .heading-tertiary {
      text-align: left;
    }
    
    .heading-quarternary {
      text-align: left;
    }
  
    @include for-tablet-portrait-up {
      .heading-primary {
        margin-top: .2rem;
        text-align: left;
        font-weight: 400;
        font-size: 3rem;
      }

      .newsletter-wrapper {
        position: absolute;
        left: 50%;
        top: -2.5rem;
    
        &__container{
          position: relative;
          left: -50%;
          top: 5.4rem;
        }
      }

      .newsletter-link {
        color: #f3eeee;
        text-decoration: none;
        font-size: 1.2rem;
      }

      .heading-secondary {
        text-align: center;
      }
      
      .heading-tertiary {
        text-align: center;
      }
      
      .heading-quarternary {
        text-align: center;
      }
    }
  
    @include for-phone-only {
  
      &__header-wrapper {
        top: -1.6rem;
      }
  
      .heading-primary {
        margin-top: -0.3rem;
        text-align: left;
   
        font-size: 2.5rem;
      }
      .newsletter-wrapper {
        position: absolute;
        left: 50%;
        top: -2.5rem;
    
        &__container{
          position: relative;
          left: -50%;
          top: 5.4rem;
        }
      }
      .newsletter-link {
        color: #f3eeee;
        text-decoration: none;
        font-size: 1.0rem;
      }

      .newsletter-link-bottom {
        color: black;
        text-decoration: none;
        font-size: 1.2rem;
      }
      
    }
  }
  