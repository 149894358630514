.main-nav {
    position: relative;
    height: auto;
    @include clearfix;

    &__title {
        position: absolute;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        overflow: hidden;
        width: 20rem;

        @include for-tablet-landscape-up {
            position: unset;
        }

        img {
            width: 20rem;

            @include for-phone-only {  
                display: none;
            }    
        }
    }

    &__list {
        background-color: var(--header-color);
        height: 5rem;
        transition: width .6s;


        &__left {  
            float: left;
            
            @include for-tablet-landscape-up {
                float: unset;
              }
              
        }
        
        &__right {
            float: right;
       
            @include for-tablet-landscape-up {
                float: unset;
            }
        }
        
        ul {
            list-style: none;
            height: 100%;
    
            @include for-tablet-landscape-up {
                height: unset;
            }
    
            li {
                display: block;
                height: 100%;
                width: 15rem;
                float: left;
    
                @include for-desktop-up {
                    font-size: 1.7rem;
                    font-weight: 300;
                    width: 13rem;
                }
    
                @include for-tablet-landscape-up {               
                    float: unset;
                    margin: 0 auto;   
                    height: 10rem;  
                    width: 100%;
                }
    
                a  {
                    display: inline-block;
                    padding-top: 3rem;
                    text-decoration: none;
                }
    
                button {
                    font: inherit;
                    cursor: pointer;
                    background-color: inherit;
                    border: none;
                    border-bottom: .5rem solid transparent;
                    padding-bottom: .7rem;
                }
            
                a,
                button {
                    height: 100%;
                    width: 100%;
                    color: var(--header-button-text-color);
                    font-weight: 100;
                    font-size: 1.3em;
            
                    &:hover{
                        background-color: var(--header-button-bg-color-hover);
                        border-bottom: .5rem solid var(--header-button-border-color);
                    }
            
                    &:active {
                        background-color: var(--header-button-bg-color-active);
                        border-bottom: .5rem solid var(--header-button-border-color);
                    }
            
                    &.selected {
                        border-bottom: .5rem solid var(--header-button-border-color);
                    }
                }
            }
        }
    }

    // Mobile Navigation Layout
    &__checkbox {
        display: none;
    }

    &__button {
        display: none;
        position: fixed;
        text-align: center;
        top: 3rem;
        left: 3rem;
        height: 7rem;
        width: 7rem;
        z-index: 2000;
        cursor: pointer;
        background-color: var(----header-button-bg-color);
        box-shadow: 0 .5rem 1rem rgba(black, .1);

        @include for-tablet-landscape-up {
            display: unset;
          }

        @include for-phone-only {  
            height: 5rem;
            width: 5rem;
            top: 2rem;
            left: 1rem;
        }    
    }

    &__background {
        display: none;
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        position: fixed;
        top: 5rem;
        left: 5rem;
        z-index: 1000;
        transition: all .6s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        background-image: radial-gradient($third-color, $primary-color);

        @include for-tablet-landscape-up {
            display: unset;
            opacity: 0;
          }

        @include for-phone-only {  
            top: 3rem;
            left: 2rem;
        }    
    }

    // Mobile navigation Functionality
    &__checkbox:checked ~ &__background {
        @include for-tablet-landscape-up {
            transform: scale(90);
            opacity: 1;
          }
    }

    &__checkbox:checked ~ &__list {
        @include for-tablet-landscape-up {
            display: block;
            position: relative;
            opacity: 1;
            width: 100%;
            z-index: 1500;
            background-color: transparent;
            height: 100%;
          }
    }

    &__checkbox:checked ~ &__title{
        @include for-tablet-landscape-up {
            display: none;
          }
    }

    &__checkbox:checked + &__button &__icon {
        background-color: transparent;
    }

    &__checkbox:checked + &__button &__icon:before {
        top: 0;
        transform: rotate(135deg);
    }

    &__checkbox:checked + &__button &__icon:after {
        top: 0;
        transform: rotate(-135deg);
    }

    &__button:hover &__icon::before {
        top: -1rem;
    }

    &__button:hover &__icon::after {
        top: 1rem;
    }

    &__icon {
        position: relative;
        margin-top: 3.4rem;

        @include for-phone-only {  
            margin-top: 2.5rem;
        }   
        
        &,
        &::before,
        &::after {
            display: inline-block;
            width: 3rem;
            height: 2px;
            background-color: grey;
        }

        &::before,
        &::after {
            position: absolute;
            left: 0;
            content: "";
            transition: all .2s;
        }

        &::before {
            top: -.8rem;
        }

        &::after {
            top: .8rem;
        }
    }

}
