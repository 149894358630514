.annex {
    margin-bottom: 1rem;
    text-align: left;


    &__container{
        display: inline-block;
    }

    &__single {
        @include publication_animation;   
        display: inline-block;
        
        margin-top: 1rem;
        margin-left: .5rem;
        width: 30%;
        height: auto; 
        box-shadow: 0rem 0rem .3rem rgba(0,0,0,.4);

        @include for-tablet-landscape-up  {
            width: 90%;
            height: auto;  
        }

        @include for-tablet-portrait-up {
            width: 49%;
            height: auto; 
        }

        &__cover { 
            img {
                margin: 1rem;
                width: 90%;
                height: auto;
            }
        }
    }
}
