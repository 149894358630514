.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: var(--footer-bg-color);
    text-align: center;
    border-top: 5px solid var(--footer-border-color);
    padding-top: 2rem;
    padding-bottom: 2rem;

    ul {
      list-style: none;

      li {
        text-align: left;     

        a {
            display: inline-block;
            padding-top: 1rem;
            text-decoration: none;
            color: #000;
            border-bottom: 2px solid transparent;

          &:hover{
            border-bottom: 2px solid var(--header-color);
          }

          &:active {
            border-bottom: 2px solid var(--header-color);
          }

          &.selected {
              border-bottom: 2px solid var(--header-color);
          }
        }
      }
    }

    button {
      display: inline-block;
      padding-top: 1rem;
      text-decoration: none;
      background-color: transparent;
      border: none;
      border-bottom: 2px solid transparent;
      cursor: pointer;
   
      &:hover{
        border-bottom: 2px solid var(--header-color);
      }

      &:active {
        border-bottom: 2px solid var(--header-color);
      }

      &.selected {
          border-bottom: 2px solid var(--header-color);
      }
    }
}