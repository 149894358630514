*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
}

.portal {
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background-color: #fff;
}
