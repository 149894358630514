.button {

    &__main {
        background-color: var(--button-main-color);
        margin: 1rem;
        width: 15rem;
        height: 5rem;
        border: none;
        border-radius: 1rem;
        color: var(--button-main-text-color);
        transition: all .2s;

        @include for-phone-only {
            width: 90%;
        }

        &:hover {
            transform: translateY(-.1rem);
            box-shadow: 0 .2rem 1rem rgba(0,0,0,.3);
        }
    
        &:active {
            box-shadow: 0 .1rem .4rem rgba(0,0,0,.1);
        }
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        font: inherit;
        cursor: pointer;
        text-decoration: none;       
    }
}