.sections {

    @include for-phone-only  {
        margin-top: 1rem;
    }

    &__container{
        display: inline-block;
    }
    
    &__single {
        @include publication_animation;   
        display: inline-block;

        margin-top: 1rem;
        margin-left: .5rem;
        width: 32%;
        height: auto; 
        box-shadow: 0rem 0rem .3rem rgba(0,0,0,.4);

        @include for-desktop-up  {
            width: 49%;
            height: auto;  
        }

        @include for-tablet-landscape-up  {
            width: 90%;
            height: auto;  
        }

        @include for-tablet-portrait-up {
            width: 30%;
            height: auto;  
        }

        @include for-phone-only  {
            width: 49%;
            height: auto;
        }

        &__cover { 
            img {
                margin: 1rem;
                width: 90%;
                height: auto;
            }
        }
    }
}
