$css-vars-use-native: true;

$primary-color: #AAD16B;
$secondary-color: #014A94;
$third-color: #fff;
$fourth-color: #000;

$success-color: #008000;
$error-color: #A94442;

// Font
$default-font-size: 1.6rem;

// GRID
$grid-width: 90vw;
$gutter-vertical: 4rem;
$gutter-horizontal: 6rem;

@include css-vars((
    "--header-color": "#fff",
    "--header-title-color": "#000",
  
    "--header-button-bg-color": "#fff",
    "--header-button-bg-color-hover": "#f2f2f2",
    "--header-button-bg-color-active": "#efefef",
    "--header-button-text-color": "#014A94",
    "--header-button-border-color": "#AAD16B",
  
    "--button-main-color": "#AAD16B",
    "--button-main-text-color": "#fff",
  
    "--breadcrumb-text-color": "#014A94",
    "--breadcrumb-underline-color": "#AAD16B",
  
    "--footer-bg-color": "#fff",
    "--footer-border-color": "#AAD16B",
    "--footer-copyright-color": "#014A94"
));
