.table {
    margin: 0 auto;
    width: 50%;
    padding-bottom: 5rem;

    @include for-tablet-landscape-up  {
        width: 75%;
    }

    @include for-phone-only  {
        width: 95%;
    }


    &__vertical {
        margin: 3rem auto;
        text-align: left;
        border-collapse: collapse;

        tr:not(:last-child) {
            border-bottom: .1rem solid #000;
        }

        th {
            border-right: .1rem solid #000;
            background-color: #888;
        }

        th,
        td {
            padding: 1rem;
        }
    }

    .MuiTableCell-body {
        font-size: 1.6rem;
        @include for-phone-only  {
            font-size: 1.2rem;
        }
    }

    .MuiIcon-root{
        font-size: 2.5rem;
        @include for-phone-only  {
            font-size: 2rem;
        }
    }

    .MuiFormControl-root{
        width: 100%;
    }

    .MuiInputBase-input {
        font-size: 1.6rem;
        @include for-phone-only  {
            font-size: 1.2rem;
        }
    }
    
    .MuiTypography-h6 {
        font-size: 1.6rem;
        @include for-phone-only  {
            font-size: 1.2rem;
        }
    }
    
    .MTableToolbar-root-32{
        min-height: 0;
    }
}

.MuiTooltip-popper {
    .MuiTooltip-tooltip{
        font-size: 1.5rem;
        @include for-phone-only  {
            font-size: 1rem;
        }
    }
}

