.search-publication-section {
    
    .search-bar {
        width: 75%;
        height: 5rem;
        padding-left: 1rem;

        @include for-tablet-landscape-up {
            width: 90%;
        }
    }
}