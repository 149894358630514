.publication-recent {
    margin-bottom: .5rem;
    text-align: left;

    &__container{
        overflow: auto;
        text-align: left;
        margin-top: 1rem;
        height: 73.5rem;

        @include for-tablet-landscape-up  {
            height: 55rem;
        }

        @include for-phone-only {
            text-align: center;
        }
    }
    
    &__date {
        .heading-tertiary {
            text-transform: capitalize;
            border-bottom: 1px solid gray;
            font-style: italic;
        }

        &__single {
            display: inline-block;

            margin-top: 1rem;
            margin-left: .5rem;
            width: 32%;
            box-shadow: 0rem 0rem .3rem rgba(0,0,0,.4);
    
            @include publication_animation;   
    
            @include for-desktop-up {
                width: 32%;
            }
    
            @include for-phone-only {
                text-align: center;
                width: 52%;
            }
    
            &__cover {
                img {
                    width: 100%;
                }
            }
        }
    }


}



