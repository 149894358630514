body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $default-font-size;
  }

  button {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1.6rem;
  }

.heading-primary {
  margin: 0 auto;
  font-size: 4rem;
  font-weight: 300;
  padding-top: 2rem;
  margin-bottom: -1rem;

  @include for-phone-only{
    font-size: 3rem;
    font-weight: 400;
    width: 24rem;
  }
}

.heading-secondary {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid gray;
  text-align: center;

  @include for-phone-only{
    font-weight: 600
  }
}

.heading-tertiary {
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 1rem;
  text-align: center;
}

.heading-quarternary {
  font-size: 1.6rem;
  font-weight: 300;
  margin: 1rem;
  text-align: center;

  @include for-phone-only  {
    font-size: 1.3rem;
  }
}

.error {
    color: $error-color;
    margin-bottom: 2rem;
}

.success {
  color: $success-color;
  margin-bottom: 2rem;
}