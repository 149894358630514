.ev {    
    .alert {
        box-shadow: unset;
        text-shadow: unset;

        &-danger {
            color: $error-color;
            background-image: none;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            padding: 0;
            margin: 0;

            margin-bottom: 2rem;
        }

        &-success {
            color: $success-color;
            background-image: none;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            padding: 0;
            margin: 0;

            margin-bottom: 2rem;
        }

        &-info {
            color: $success-color;
            background-image: none;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            padding: 0;
            margin: 0;

            margin-bottom: 2rem;
        }

        &-warning {
            color: $error-color;
            background-image: none;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            padding: 0;
            margin: 0;

            margin-bottom: 2rem;
        }
    }

    .form-group {
        text-align: center;
    }

    .form-horizontal .control-label {
        text-align: right;
    }

    .col-sm-10 {
        width: 67%;
    }
}

#widget-body {
    .widget-input {
        font-size: 1.5rem;
        border: .1rem solid #ccc;
        transition: all .2s;
    
        &:focus,
        &:hover {
            border: .1rem solid #ccc;
            box-shadow: 0 0 .5rem $primary-color;
        }
    }

    .widget-label {
        font-size: 1.5rem;
    }
}
