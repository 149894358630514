@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin publication_animation {
    transition: all .2s;
    animation-name: moveInBottom;
    animation-duration: 1s;
    animation-timing-function: ease-out; 
    cursor: pointer;

    &:hover {
        transform: translateY(-.3rem);
        box-shadow: 0 .1rem 2rem rgba(0,0,0,.3);
    }

    &:active {
        transform: translateY(-.1rem);
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.3);
    }

}

@mixin for-phone-only {
    @media (max-width: 600px) { @content; }
}

@mixin for-tablet-portrait-up {
    @media (max-width: 900px) { @content; }
}

@mixin for-tablet-landscape-up {
    @media (max-width: 1200px) { @content; }
}

@mixin for-desktop-up {
    @media (max-width: 1600px) { @content; }
}

@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}