.mobile-menu-display-none {
    @include for-tablet-landscape-up {
        display: none;
    }
}

.search-bar {
    border-radius: 10px;
    height: 4rem;

    @include for-tablet-landscape-up {
        height: 3rem;
    }
}