.row {
    margin: 0 auto;
    max-width: $grid-width;

    //everything except last child
    &:not(:last-child) {
        margin-bottom: $gutter-vertical;
    }

    @include clearfix;

    [class^="col-"]{
        float: left;

        &:not(:last-child) {
            margin-right: $gutter-horizontal;
        }

        @include for-tablet-portrait-up {
            float: unset;
        }

    }

    // Column of two

    .col-1-of-2 {
        width: calc((100% - #{$gutter-horizontal}) / 2);
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }


    // Column of three

    .col-1-of-3 {
        width: calc((100% - 2 * #{$gutter-horizontal}) / 3);
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-2-of-3 {
        width: calc(2 * ((100% - 2 * #{$gutter-horizontal}) / 3) + #{$gutter-horizontal});
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    // Column of four


    .col-1-of-4 {
        width: calc((100% - 3 * #{$gutter-horizontal}) / 4);
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-2-of-4 {
        width: calc(2 * ((100% - 3 * #{$gutter-horizontal}) / 4) + #{$gutter-horizontal});
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-3-of-4 {
        width: calc(3 * ((100% - 3 * #{$gutter-horizontal}) / 4) + (2 * #{$gutter-horizontal}));
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    // Column of eight

    .col-1-of-8 {
        width: calc((100% - 7 * #{$gutter-horizontal}) / 8);
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-2-of-8 {
        width: calc(2 * ((100% - 7 * #{$gutter-horizontal}) / 8) + #{$gutter-horizontal});
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-3-of-8 {
        width: calc(3 * ((100% - 7 * #{$gutter-horizontal}) / 8) + (2 * #{$gutter-horizontal}));
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-4-of-8 {
        width: calc(4 * ((100% - 7 * #{$gutter-horizontal}) / 8) + (3 * #{$gutter-horizontal}));
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-5-of-8 {
        width: calc(5 * ((100% - 7 * #{$gutter-horizontal}) / 8) + (4 * #{$gutter-horizontal}));
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-6-of-8 {
        width: calc(6 * ((100% - 7 * #{$gutter-horizontal}) / 8) + (5 * #{$gutter-horizontal}));
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-7-of-8 {
        width: calc(7 * ((100% - 7 * #{$gutter-horizontal}) / 8) + (6 * #{$gutter-horizontal}));
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    // Column of ten

    .col-1-of-10 {
        width: calc((100% - 9 * #{$gutter-horizontal}) / 10);
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-2-of-10 {
        width: calc(2 * ((100% - 9 * #{$gutter-horizontal}) / 10) + #{$gutter-horizontal});
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-3-of-10 {
        width: calc(3 * ((100% - 9 * #{$gutter-horizontal}) / 10) + (2 * #{$gutter-horizontal}));
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-4-of-10 {
        width: calc(4 * ((100% - 9 * #{$gutter-horizontal}) / 10) + (3 * #{$gutter-horizontal}));
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-5-of-10 {
        width: calc(5 * ((100% - 9 * #{$gutter-horizontal}) / 10) + (4 * #{$gutter-horizontal}));
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-6-of-10 {
        width: calc(6 * ((100% - 9 * #{$gutter-horizontal}) / 10) + (5 * #{$gutter-horizontal}));
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-7-of-10 {
        width: calc(7 * ((100% - 9 * #{$gutter-horizontal}) / 10) + (6 * #{$gutter-horizontal}));
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-8-of-10 {
        width: calc(8 * ((100% - 9 * #{$gutter-horizontal}) / 10) + (7 * #{$gutter-horizontal}));
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }

    .col-9-of-10 {
        width: calc(9 * ((100% - 9 * #{$gutter-horizontal}) / 10) + (8 * #{$gutter-horizontal}));
        @include for-tablet-portrait-up  {
            width: 100%;
        }
    }
}